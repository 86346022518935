<template>
  <!-- <v-app> -->
   

 <div>
  
 <head_header/>
 
  <router-view />
 <footers />
 </div>

  
</template>

<script>
  export default {
    name: "DashboardIndex",

    components: {
      head_header: () => import("../../components/core/header.vue"),
       footers: () => import("../../components/core/footer.vue"),
      // DashboardCoreView: () => import("../../components/core/View")
    },

    data: () => ({
      expandOnHover: false
    })
  };
</script>